import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import api from "../api"
import _ from "lodash"

Vue.use(Vuex)

function getMarkerIcons() {
	const cat = [
		"archeological",
		"art",
		"buildings",
		"church",
		"festivals",
		"food",
		"history",
		"literature",
		"monuments",
		"museums",
		"events",
		"infopoint",
		"music",
		"nature",
		"theatre",
		"unesco",
		"wine",
		"cinema",
		"villages",
		"stores",
		"civic_places"
	]
	const normal = []
	const sponsor = []
	const event = []
	const event_sponsor = []

	for (const c of cat) {
		normal[c] = L.icon({
			iconUrl: `map-icons/${c}.png`,
			iconSize: [24, 24],
			iconAnchor: [12, 12],
			className: "category-map-icon"
		})
		sponsor[`${c}_sponsor`] = L.icon({
			iconUrl: `map-icons/${c}.png`,
			iconSize: [24, 24],
			iconAnchor: [12, 12],
			className: "category-map-icon sponsor"
		})
		event[`${c}_event`] = L.icon({
			iconUrl: `map-icons/${c}-ev.svg`,
			iconSize: [28, 28],
			iconAnchor: [14, 14],
			className: "category-map-icon event"
		})
		event_sponsor[`${c}_event_sponsor`] = L.icon({
			iconUrl: `map-icons/${c}-ev.svg`,
			iconSize: [28, 28],
			iconAnchor: [14, 14],
			className: "category-map-icon event-sponsor"
		})
	}

	return { normal, sponsor, event, event_sponsor }
}

const icons = getMarkerIcons()

export default new Vuex.Store({
	state: {
		isLogged: false,
		username: "",
		user: {},
		language: "it",
		mapboxKey: "pk.eyJ1Ijoic3RlbmRoYXBwIiwiYSI6ImNqc2cwcmhzdjFpeHg0YW1scWFjcDllNWkifQ.VIGmWdDzS6631XtHFDVA_Q",
		filters: {
			archeological: false,
			art: false,
			buildings: false,
			church: false,
			festivals: false,
			food: false,
			history: false,
			literature: false,
			monuments: false,
			museums: false,
			// events: false,
			infopoint: true,
			music: false,
			nature: false,
			theatre: false,
			unesco: false,
			wine: false,
			cinema: false,
			villages: false,
			stores: false,
			civic_places: true
		},
		targetPos: {
			coords: null,
			accuracy: null,
			location: null,
			category: null,
			id: null,
			lat: null,
			lon: null,
			time: null
		},
		currentPos: {
			coords: null,
			accuracy: null,
			location: null,
			lat: null,
			lon: null,
			time: null
		},
		routeDetail: {},
		mapIcons: {
			current: L.icon({
				iconUrl: "map-icons/ico-current.png",
				iconSize: [50, 50],
				iconAnchor: [25, 25]
			}),
			...icons.normal,
			...icons.sponsor,
			...icons.event,
			...icons.event_sponsor
		},
		items: [
			{ id: "1", type: "museums", title: "Titolo 1", coords: [45.464203, 9.191721] },
			{ id: "2", type: "monuments", title: "Titolo 2", coords: [45.463471, 9.185727] },
			{ id: "3", type: "church", title: "Titolo 3", coords: [45.462944, 9.185269] },
			{ id: "4", type: "art", title: "Titolo 4", coords: [45.462837, 9.187548] },
			{ id: "5", type: "buildings", title: "Titolo 5", coords: [45.463042, 9.191241] },
			{ id: "6", type: "unesco", title: "Titolo 6", coords: [45.463142, 9.192241] },
			{ id: "7", type: "theatre", title: "Titolo 7", coords: [45.463242, 9.193241] },
			{ id: "8", type: "music", title: "Titolo 8", coords: [45.463342, 9.194241] },
			{ id: "9", type: "nature", title: "Titolo 9", coords: [45.463442, 9.195241] },
			{ id: "10", type: "food", title: "Titolo 10", coords: [45.463542, 9.196241] },
			{ id: "11", type: "valleys", title: "Titolo 11", coords: [45.463642, 9.197241] },
			{ id: "12", type: "activities", title: "Titolo 12", coords: [45.463742, 9.198241] },
			{ id: "13", type: "activities", title: "Titolo 13", coords: [38.149669, 13.335309] },
			{ id: "14", type: "museums", title: "Titolo 14", coords: [38.155324, 13.327822] },
			{ id: "15", type: "food", title: "Titolo 15", coords: [38.09689, 13.424411] },
			{ id: "16", type: "nature", title: "Titolo 16", coords: [38.07898, 13.437702] },
			{ id: "17", type: "art", title: "Hc Art Factory", coords: [45.485439, 9.213164] },
			{ id: "18", type: "church", title: "Chiesa Cattolica Parrocchiale S. Raffaele Arcangelo", coords: [38.097774, 13.350153] }
		],
		pdiList: [],
		lastMapSearch: "",
		draftList: [],
		sponsors: []
	},
	mutations: {
		login(state, payload) {
			state.isLogged = true
			if (payload && payload.name) {
				state.username = payload.name
				state.user = payload.user || {}
			}
		},
		logout(state) {
			state.isLogged = false
			state.username = ""
			state.user = {}
		},
		setUser(state, payload) {
			if (payload.firstName) {
				state.user.firstName = payload.firstName
				state.user.name = payload.firstName
				state.username = payload.firstName
			}
			if (payload.lastName) {
				state.user.lastName = payload.lastName
			}
			if (payload.gender) {
				state.user.gender = payload.gender
			}
			if (payload.privacy_level_1 !== undefined) {
				state.user.privacy_level_1 = payload.privacy_level_1
			}
			if (payload.privacy_level_2 !== undefined) {
				state.user.privacy_level_2 = payload.privacy_level_2
			}
			if (payload.privacy_level_3 !== undefined) {
				state.user.privacy_level_3 = payload.privacy_level_3
			}
		},
		setLanguage(state, lang) {
			state.language = lang
		},
		setFilter(state, payload) {
			state.filters[payload.filter] = payload.value
		},
		setRouteDetail(state, payload) {
			if (payload) state.routeDetail = payload
		},
		setTargetPos(state, payload) {
			if (payload.coords) state.targetPos.coords = payload.coords
			if (payload.accuracy) state.targetPos.accuracy = payload.accuracy
			if (payload.location) state.targetPos.location = payload.location
			if (payload.category) state.targetPos.category = payload.category
			if (payload.id) state.targetPos.id = payload.id
			if (payload.lat) state.targetPos.lat = payload.lat
			if (payload.lon) state.targetPos.lon = payload.lon
			if (payload.time) state.targetPos.time = payload.time
		},
		setCurrentPos(state, payload) {
			if (payload.coords) state.currentPos.coords = payload.coords
			if (payload.accuracy) state.currentPos.accuracy = payload.accuracy
			if (payload.location) state.currentPos.location = payload.location
			if (payload.lat) state.currentPos.lat = payload.lat
			if (payload.lon) state.currentPos.lon = payload.lon
			if (payload.time) state.currentPos.time = payload.time
		},
		resetTargetPos(state, payload) {
			state.targetPos.coords = null
			state.targetPos.accuracy = null
			state.targetPos.location = null
			state.targetPos.category = null
			state.targetPos.id = null
			state.targetPos.lat = null
			state.targetPos.lon = null
			state.targetPos.time = null
		},
		setPDI(state, payload) {
			state.pdiList = payload
		},
		setMapSearch(state, payload) {
			state.lastMapSearch = payload
		},
		addDraft(state, payload) {
			state.draftList.push({
				id: payload.id || moment().format("x"),
				dateInsert: payload.dateInsert || moment().toDate(),
				image: payload.image || "",
				name: payload.name || "",
				catSelected: payload.catSelected || null,
				description: payload.description || "",
				address: payload.address || "",
				accessible: payload.accessible || false,
				phoneNumber: payload.phoneNumber || "",
				timeFrom: payload.timeFrom || null,
				timeTo: payload.timeTo || null,
				ticketCost: payload.ticketCost || "",
				source: payload.source || ""
			})
		},
		delDraft(state, payload) {
			state.draftList = state.draftList.filter(i => i.id !== payload.id)
		},
		pushSponsor(state, payload) {
			const list = _.cloneDeep(state.sponsors)
			const sponsors = _.chain(payload)
				.filter(i => i.sponsor === true)
				.map(i => {
					i.shown = false
					return i
				})
				.value()
			state.sponsors = _.uniqBy([...list, ...sponsors], i => i._id)
			console.log("pushSponsor", sponsors, state.sponsors?.length)
		},
		setSponsorShown(state, payload) {
			const list = _.cloneDeep(state.sponsors)
			const item = _.find(list, i => i._id === payload)
			item.shown = true
			state.sponsors = _.cloneDeep(list)
		}
	},
	actions: {
		login(context, payload) {
			return new Promise((resolve, reject) => {
				api.auth
					.login(payload.username, payload.password)
					.then(data => {
						if (data && data.status && data.status === 200) {
							api.auth
								.userinfo("all")
								.then(info => {
									if (info && info.status && info.status === 200 && info.body) {
										const user = data.body
										user.privacy_level_1 = info.body.privacy_level_1
										user.privacy_level_2 = info.body.privacy_level_2
										user.privacy_level_3 = info.body.privacy_level_3

										context.commit("login", { name: data.body.name, user })

										const filters = _.get(info, "body.settings.filters", []) || []
										for (const f of filters) {
											context.commit("setFilter", { filter: f.name, value: f.active })
										}

										resolve(info)
									} else {
										reject(info)
									}
								})
								.catch(err => {
									reject(err)
								})
						} else {
							reject(data)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		logout(context, payload) {
			return new Promise((resolve, reject) => {
				context.commit("logout")
				resolve({ ok: true })
			})
		},
		registration(context, payload) {
			return new Promise((resolve, reject) => {
				api.auth
					.registration(
						payload.email,
						payload.password,
						payload.firstName,
						payload.lastName,
						payload.gender,
						payload.city,
						payload.state,
						payload.age,
						payload.latitude,
						payload.longitude,
						payload.privacy_level_1,
						payload.privacy_level_2,
						payload.privacy_level_3
					)
					.then(data => {
						if (data && data.status && data.status === 200) {
							//context.commit('login', {name:payload.firstName})
							resolve(data)
						} else {
							reject(data)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		getPDI(context, payload) {
			payload = payload || {}
			return new Promise((resolve, reject) => {
				const categories = context.getters.isFiltersAllSelected ? "" : context.getters.filtersActive.join("|")

				api.pdi
					.getAll({
						id: "short",
						filters: categories,
						currentZoom: payload.currentZoom,
						maxZoom: payload.maxZoom,
						ne: payload.ne,
						sw: payload.sw
					})
					.then(data => {
						if (data && data.status && data.status === 200) {
							context.commit("pushSponsor", data.body)
							context.commit("setPDI", data.body)
							resolve(data)
						} else {
							reject(data)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		setOneFilter(context, payload) {
			for (const filter in context.state.filters) {
				let value = false

				if (filter === payload) {
					value = true
				}
				context.commit("setFilter", { filter, value })
			}
		},
		setFilter(context, payload) {
			context.commit("setFilter", { filter: payload.filter, value: payload.value })
			return new Promise((resolve, reject) => {
				api.auth
					.updateFilters({
						filters: context.getters.filtersParsed
					})
					.then(data => {
						if (data && data.status && data.status === 200) {
							resolve(data)
						} else {
							reject(data)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		setLanguage(context, payload) {
			context.commit("setLanguage", payload)
			return new Promise((resolve, reject) => {
				api.auth
					.updateLanguage({
						language: payload
					})
					.then(data => {
						if (data && data.status && data.status === 200) {
							resolve(data)
						} else {
							reject(data)
						}
					})
					.catch(err => {
						reject(err)
					})
			})
		}
	},
	getters: {
		language(state, getters) {
			return state.language
		},
		username(state, getters) {
			return state.username
		},
		fullName(state, getters) {
			return (state.user.firstName || "") + " " + (state.user.lastName || "")
		},
		user(state, getters) {
			return state.user
		},
		lastMapSearch(state, getters) {
			return state.lastMapSearch
		},
		filter(state, getters) {
			let filters_selected = Object.keys(state.filters).length

			for (const key in state.filters) {
				if (!state.filters[key]) filters_selected--
			}

			return (filter, real) => {
				return state.filters[filter] || (!!!real && !filters_selected)
			}
		},
		pdiList(state, getters) {
			return state.pdiList.map(item => {
				item.coords = [item.latitude, item.longitude]
				return item
			})
		},
		pdiListNoSponsor(state, getters) {
			return state.pdiList
				.map(item => {
					item.coords = [item.latitude, item.longitude]
					return item
				})
				.filter(item => !item.sponsor)
		},
		pdiListOnlySponsor(state, getters) {
			return state.pdiList
				.map(item => {
					item.coords = [item.latitude, item.longitude]
					return item
				})
				.filter(item => item.sponsor === true)
		},
		draftList(state, getters) {
			return state.draftList
		},
		isFiltersAllSelected(state, getters) {
			const filters_count = Object.keys(state.filters).length
			const filtersActive = _.filter(state.filters, i => i === true).length

			return filters_count === filtersActive
		},
		filtersParsed(state, getters) {
			const filters = []
			for (const key in state.filters) {
				filters.push({
					name: key,
					active: getters.isFiltersAllSelected ? true : state.filters[key]
				})
			}

			return filters
		},
		filtersActive(state, getters) {
			return _.filter(getters.filtersParsed, i => i.active === true).map(i => i.name)
		},
		canGeolocation(state, getters) {
			return state.user && state.user.privacy_level_1
		},
		popupSponsors(state, getters) {
			const filters = getters.filtersActive
			return state.sponsors.filter(item => {
				return !item.shown && filters.includes(item.category)
			})
		}
	},
	plugins: [createPersistedState({ paths: ["isLogged", "language", "filters", "username", "user", "draftList", "sponsors"] })]
})
