<template>
	<div class="settings flex column">
		<v-ons-toolbar>
			<div class="left"></div>
			<div class="center">{{ $t("Settings") }}</div>
			<div class="right">
				<v-ons-toolbar-button @click="closeModal">OK</v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>

		<div class="page-wrapper box grow">
			<div class="page-content">
				<div class="button-box">
					<div class="label">{{ $t("Language") }}</div>
					<div class="button-set flex">
						<button class="grow box" :class="{ active: language === 'it' }" @click="setLanguage('it')" aria-label="change locale it">
							{{ $t("Italian") }}
						</button>
						<button class="grow box" :class="{ active: language === 'en' }" @click="setLanguage('en')" aria-label="change locale en">
							{{ $t("English") }}
						</button>
					</div>
				</div>
				<div class="button-box">
					<div class="label">{{ $t("Choose your beauty") }}</div>
					<div class="button-grid">
						<div class="buttons-row flex justify-space-between" v-for="(r, index) in grid" :key="index">
							<div class="btn-box" :class="{ active: filter(c.key) }" v-for="c in r" :key="c.key">
								<button @click="toggleFilter(c.key)" aria-label="toggle category">
									<img :src="'map-icons/' + c.key + '.png'" v-if="filter(c.key)" alt="category-icon" />
									<img :src="'map-icons/' + c.key + '-bw.png'" v-if="!filter(c.key)" alt="category-icon" />
								</button>
								<div class="btn-label">{{ $t(c.label) }}</div>
							</div>
						</div>
					</div>
				</div>

				<small class="version">v{{ version }}</small>
			</div>
		</div>
	</div>
</template>

<script>
import { EventBus } from "@/event-bus.js"

export default {
	name: "settings",
	data() {
		return {
			version: process.env.VUE_APP_VERSION,
			grid: [
				[
					{
						key: "museums",
						label: "Museums"
					},
					{
						key: "church",
						label: "Church"
					},
					{
						key: "monuments",
						label: "Monuments"
					}
				],
				[
					{
						key: "buildings",
						label: "Buildings"
					},
					{
						key: "archeological",
						label: "Archeological Area"
					},
					{
						key: "art",
						label: "Art"
					}
				],
				[
					{
						key: "food",
						label: "Food"
					},
					{
						key: "wine",
						label: "Wine",
						width: "45%"
					},
					{
						key: "civic_places",
						label: "Civic Places"
					}
				],
				[
					{
						key: "music",
						label: "Music"
					},
					{
						key: "theatre",
						label: "Theatre"
					},
					{
						key: "unesco",
						label: "Unesco"
					}
				],
				[
					{
						key: "literature",
						label: "Literature"
					},
					{
						key: "history",
						label: "History"
					},
					{
						key: "nature",
						label: "Nature"
					}
				],
				[
					{
						key: "villages",
						label: "Villages"
					},
					{
						key: "cinema",
						label: "Cinema"
					},
					{
						key: "stores",
						label: "Stores"
					}
				],
				[
					{
						key: "events",
						label: "Events"
					}
				]
			]
		}
	},
	computed: {
		language() {
			return this.$store.getters.language
		},
		filter() {
			return filter => {
				return this.$store.getters.filter(filter, true)
			}
		}
	},
	methods: {
		closeModal() {
			this.$emit("close")
		},
		setLanguage(lang) {
			this.$i18n.locale = lang
			this.$store.dispatch("setLanguage", lang)
		},
		toggleFilter(filter) {
			EventBus.$emit("update:settings", { filter, value: !this.filter(filter) })
			this.$store.dispatch("setFilter", { filter, value: !this.filter(filter) })
		}
	},
	components: {}
}
</script>

<style lang="scss" scoped>
.settings {
	height: 100%;
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	background: #fff;
	max-width: 640px;
	margin: 0 auto;
	color: #000;

	.page__background {
		background: #fff;
	}

	.page-wrapper {
		padding: 20px;
		text-align: left;
		box-sizing: border-box;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		padding-top: 65px;
	}

	.version {
		margin-bottom: 10px;
		display: block;
		opacity: 0.3;
		position: relative;
		color: #000;
		text-align: center;
	}
}
</style>

<style lang="scss">
.settings {
	.toolbar {
		.toolbar__title {
			color: #000;
		}
		.toolbar-button {
			color: #000;
			opacity: 0.5;
		}
	}

	.button-box {
		padding: 20px;
		text-align: left;
		max-width: 300px;
		margin: 0 auto;

		.label {
			font-size: 14px;
			opacity: 0.7;
			margin-bottom: 12px;
		}
		.button-set {
			border-radius: 4px;
			overflow: hidden;
			padding: 3px;
			background: #fff;
			box-shadow: 0px 3px 10px 0px rgba(26, 101, 255, 0.2);

			button {
				border-radius: 4px;
				padding: 10px;
				background: #fff;
				border: none;
				outline: none;
				font-weight: bold;
				cursor: pointer;

				&.active {
					background: #1a65ff;
					background: -moz-linear-gradient(left, #1a65ff 0%, #34aefc 100%);
					background: -webkit-linear-gradient(left, #1a65ff 0%, #34aefc 100%);
					background: linear-gradient(to right, #1a65ff 0%, #34aefc 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a65ff', endColorstr='#34aefc',GradientType=1 );
					color: #fff;
				}
			}
		}

		.button-grid {
			text-align: center;
			margin-left: -10px;
			margin-right: -10px;

			.buttons-row {
				margin-bottom: 20px;

				.btn-box {
					width: 80px;

					button {
						width: 60px;
						height: 60px;
						box-sizing: border-box;
						background: #f0f0f0;
						padding: 5px;
						border-radius: 4px;
						border: none;
						outline: none;
						margin-bottom: 5px;
						cursor: pointer;

						img {
							width: 70%;
						}
					}
					.btn-label {
						opacity: 0.4;
						font-size: 12px;
					}

					&.active {
						button {
							background: #fff;
							box-shadow: 0px 3px 10px 0px rgba(26, 101, 255, 0.2);
						}
					}
				}
			}
		}
	}
}
</style>
