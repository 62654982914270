<template>
	<v-ons-page id="app">
		<div id="splash-screen" v-if="splashScreen"></div>

		<div id="user-verification" v-show="userVerification">
			<h1>{{ verificationText }}</h1>
		</div>

		<div id="login-box" v-if="!isLogged">
			<div class="logo-box">
				<img src="images/logo-square.png" alt="logo" />
			</div>
			<h1>{{ $t("Welcome to Stendhapp") }}!</h1>
			<h4>{{ $t("sign up for Beauty and create a free account") }}</h4>

			<div class="login-form">
				<div class="button-box flex">
					<button class="box grow" @click="loginType = 'login'" :class="{ active: loginType === 'login' }" aria-label="login">
						{{ $t("Login") }}
					</button>
					<button
						class="box grow"
						@click="loginType = 'registration'"
						:class="{ active: loginType === 'registration' }"
						aria-label="registration"
					>
						{{ $t("Register") }}
					</button>
				</div>
				<div class="input-box">
					<!--<input id="username" type="text" :placeholder="$t('Username')" v-if="loginType === 'registration'" v-model="form.username">-->

					<input id="email" type="email" placeholder="Email *" v-model="form.email" autocomplete="new-password" />
					<div v-if="form.email.length >= 5 && !isValidEmail" class="email-error">{{ $t("Invalid email") }}</div>
					<input id="n-password" type="password" placeholder="Password *" v-model="form.password" />
					<div v-if="loginType !== 'login' && form.password && form.c_password && form.password !== form.c_password" class="email-error">
						{{ $t("Passwords do not match") }}
					</div>
					<div v-if="loginType !== 'login' && form.password && !isValidPassword" class="email-error">
						{{ $t("Password not strong") }}
					</div>

					<input
						id="c-password"
						type="password"
						:placeholder="$t('Confirm Password') + ' *'"
						v-if="loginType === 'registration'"
						v-model="form.c_password"
					/>
					<input
						id="first-name"
						type="text"
						:placeholder="$t('First name') + ' *'"
						v-if="loginType === 'registration'"
						v-model="form.firstName"
					/>
					<input
						id="last-name"
						type="text"
						:placeholder="$t('Last name') + ' *'"
						v-if="loginType === 'registration'"
						v-model="form.lastName"
					/>
					<v-ons-select v-model="form.gender" v-if="loginType === 'registration'" underbar :class="{ 'not-selected': !form.gender }">
						<option value="">{{ $t("Gender") }}</option>
						<option v-for="item in genderValues" :key="item.value" :value="item.value">{{ $t(item.label) }}</option>
					</v-ons-select>
					<input id="city" type="text" :placeholder="$t('City')" v-if="loginType === 'registration'" v-model="form.city" />
					<input id="state" type="text" :placeholder="$t('State')" v-if="loginType === 'registration'" v-model="form.state" />
					<v-ons-select v-model="form.age" v-if="loginType === 'registration'" underbar :class="{ 'not-selected': !form.age }">
						<option value="">{{ $t("Age") }} *</option>
						<option v-for="(i, index) in ageValues" :key="index" :value="i">{{ i }}</option>
					</v-ons-select>
					<div class="disclaimer" v-if="loginType === 'registration'">
						<!--<div class="label">Disclaimer</div>-->
						<div class="text">{{ $t("Please follow the link below and read carefully prior to accept") }}</div>
						<!--<div class="link" @click="viewPrivacyPolicy">{{ $t("View Privacy Policy") }}</div>-->
						<a
							class="link"
							:href="langIt ? `https://stendhapp.com/faq/` : `https://stendhapp.com/en/faq-en/`"
							target="_blank"
							rel="nofollow noopener noreferrer"
						>
							{{ $t("View FAQ") }}
						</a>
						<a
							class="link"
							:href="langIt ? `https://stendhapp.com/privacy-policy/` : `https://stendhapp.com/en/privacy-policy-en/`"
							target="_blank"
							rel="nofollow noopener noreferrer"
						>
							{{ $t("View Privacy Policy") }}
						</a>
						<a
							class="link"
							:href="langIt ? `https://stendhapp.com/accordo-di-servizio/` : `https://stendhapp.com/en/service-agreement/`"
							target="_blank"
							rel="nofollow noopener noreferrer"
						>
							{{ $t("View Terms of Service") }}
						</a>
						<v-ons-list>
							<v-ons-list-item tappable>
								<label class="left">
									<v-ons-checkbox input-id="checkbox-opt1" v-model="checkT1"></v-ons-checkbox>
								</label>
								<label class="center" for="checkbox-opt1">
									{{ $t("term_1") }}
								</label>
							</v-ons-list-item>
							<v-ons-list-item tappable>
								<label class="left">
									<v-ons-checkbox input-id="checkbox-opt2" v-model="checkT2"></v-ons-checkbox>
								</label>
								<label class="center" for="checkbox-opt2">
									{{ $t("term_2") }}
								</label>
							</v-ons-list-item>
							<v-ons-list-item tappable>
								<label class="left">
									<v-ons-checkbox input-id="checkbox-opt3" v-model="checkT3"></v-ons-checkbox>
								</label>
								<label class="center" for="checkbox-opt3">
									{{ $t("term_3") }}
								</label>
							</v-ons-list-item>
							<v-ons-list-item tappable>
								<label class="left">
									<v-ons-checkbox input-id="checkbox-opt4" v-model="checkT4"></v-ons-checkbox>
								</label>
								<label class="center" for="checkbox-opt4"> {{ $t("Agree to the Terms of Service") }}* </label>
							</v-ons-list-item>
						</v-ons-list>
						<div class="text">{{ $t("To create an account you need to agree to the Terms of service") }}</div>
					</div>
				</div>
			</div>

			<button class="submit" @click="login" :disabled="!submitValid" aria-label="button" v-if="loginType === 'login'">{{ $t("Done") }}</button>
			<button class="submit" @click="checkOptin" :disabled="!submitValid" aria-label="button" v-if="loginType !== 'login'">
				{{ $t("Done") }}
			</button>

			<a class="forgot-password-btn" @click="lostPassword" v-if="loginType === 'login'">{{ $t("Forgot Password?") }}</a>

			<div class="image-square-box">
				<img src="images/squareborder.svg" alt="logo-support" />
			</div>
			<p class="sub-label">{{ $t("Beauty is all around you, turn on your device location") }}</p>
		</div>

		<main-page :class="{ hide: splashScreen }"></main-page>
	</v-ons-page>
</template>

<script>
import api from "./api"
import MainPage from "./components/MainPage"
import it_privacy from "./locale/it/privacy"
import en_privacy from "./locale/en/privacy"
import it_optin from "./locale/it/optin-alert"
import en_optin from "./locale/en/optin-alert"
import { EventBus } from "@/event-bus.js"
import _ from "lodash"

export default {
	name: "app",
	data() {
		return {
			loginType: "login",
			splashScreen: true,
			checkT1: false,
			checkT2: false,
			checkT3: false,
			checkT4: false,
			ageValues: ["0 - 13", "14 - 19", "20 - 29", "30 - 39", "40 - 49", "50 - 59", "60 - 69", "70 - 79", "80+"],
			genderValues: [
				{ label: "Male", value: "m" },
				{ label: "Female", value: "f" },
				{ label: "Prefer not to say", value: "n" }
			],
			form: {
				email: "",
				password: "",
				c_password: "",
				firstName: "",
				lastName: "",
				gender: "",
				city: "",
				state: "",
				age: ""
			},
			latitude: "",
			longitude: "",
			userVerification: false,
			forceLanguage: "it",
			verificationText: "Stiamo verificando la tua email..."
		}
	},
	computed: {
		isLogged() {
			return this.$store.state.isLogged
		},
		submitValid() {
			let ctrl = false

			if (this.loginType === "login") {
				if (this.form.email && this.isValidEmail && this.form.password) {
					ctrl = true
				}
			} else {
				if (
					//this.checkT1 &&
					//this.checkT2 &&
					//this.checkT3 &&

					this.checkT4 &&
					this.form.email &&
					this.isValidEmail &&
					this.isValidPassword &&
					this.form.password &&
					this.form.c_password &&
					this.form.firstName &&
					this.form.lastName &&
					// this.form.gender &&
					// this.form.city &&
					// this.form.state &&
					this.form.age &&
					this.form.age !== "0 - 13" &&
					this.form.password === this.form.c_password
				) {
					ctrl = true
				}
			}

			return ctrl
		},
		language() {
			return this.$store.getters.language
		},
		langIt() {
			return this.$i18n.locale === "it"
		},
		filtersParsed() {
			return this.$store.getters.filtersParsed
		},
		isValidEmail() {
			const re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return re.test(this.form.email)
		},
		isValidPassword() {
			const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/
			return re.test(this.form.password)
		},
		canGeolocation() {
			return this.$store.getters.canGeolocation
		}
	},
	methods: {
		login() {
			if (this.loginType === "login") {
				this.$store
					.dispatch("login", { username: this.form.email, password: this.form.password })
					.then(res => {
						this.form.email = ""
						this.form.password = ""

						EventBus.$emit("login")
						//this.$store.dispatch('getPDI')
					})
					.catch(err => {
						console.log(err)
						if (err) {
							this.$ons.notification.alert(err.body.msg || err.body.error || err.body || "errore", {
								title: this.$t("Login error")
							})
						}
					})
			} else {
				this.form.latitude = this.latitude
				this.form.longitude = this.longitude
				this.form.privacy_level_1 = this.checkT1
				this.form.privacy_level_2 = this.checkT2
				this.form.privacy_level_3 = this.checkT3

				this.$store
					.dispatch("registration", this.form)
					.then(res => {
						this.form.email = ""
						this.form.password = ""
						this.loginType = "login"

						this.$ons.notification.alert(
							(res.body.msg === "salvataggio eseguito"
								? this.$t(
										"The subscription to Stendhapp is almost complete... now we just need to verify your email address: we have sent you an email with a confirmation link, you just need a click and you can discover the Beauty around you"
								  )
								: res.body.msg) || res.body,
							{
								title: this.$t("Done")
							}
						)
					})
					.catch(err => {
						if (err) {
							this.$ons.notification.alert(err.body.msg || err.body || "errore", {
								title: this.$t("registration error")
							})
						}
					})
			}
		},
		checkOptin() {
			if (!this.checkT1) {
				this.viewOptinAlert()
			} else {
				this.login()
			}
		},
		viewOptinAlert() {
			switch (this.$i18n.locale) {
				case "it":
					this.$ons.notification.alert(it_optin, {
						title: this.$t("Caution"),
						buttonLabels: ["Indietro", "Prosegui"],
						callback: val => {
							if (val === 1) {
								this.login()
							}
						}
					})
					break
				case "en":
				default:
					this.$ons.notification.alert(en_optin, {
						title: this.$t("Caution"),
						buttonLabels: ["Indietro", "Prosegui"],
						callback: val => {
							if (val === 1) {
								this.login()
							}
						}
					})
					break
			}
		},
		viewPrivacyPolicy() {
			switch (this.$i18n.locale) {
				case "it":
					this.$ons.notification.alert(it_privacy, {
						title: this.$t("Privacy Policy")
					})
					break
				case "en":
				default:
					this.$ons.notification.alert(en_privacy, {
						title: this.$t("Privacy Policy")
					})
					break
			}
		},
		viewFAQ() {
			this.$ons.notification.alert("lorem ipsum", {
				title: this.$t("FAQ")
			})
		},
		lostPassword() {
			this.$ons.notification
				.prompt(this.$t("write the email address you used to sign up, we'll send you a link to create a new one"), {
					title: this.$t("Forgot Password?"),
					placeholder: "Email",
					defaultValue: this.form.email || ""
				})
				.then(email => {
					if (email) {
						this.$ons.notification.alert(
							this.$t("We have sent a new password to your email, you can change it after logging in through the settings"),
							{
								title: this.$t("Done")
							}
						)
						api.auth
							.passwordRecovery(email)
							.then(data => {
								// l'alert prima era qui
							})
							.catch(err => {
								this.$ons.notification.toast(this.$t("Error request"), { timeout: 1000, animation: "fall" })
							})
					}
				})
				.catch(err => {
					console.error(err)
				})
		},
		checkGeoLocation() {
			if (this.canGeolocation) {
				if (window.navigator.geolocation) {
					window.navigator.geolocation.getCurrentPosition(({ coords }) => {
						this.latitude = coords.latitude
						this.longitude = coords.longitude
					}, this.geoLocationError)
				} else {
					let errorMsg = this.$t("Geolocation is not supported by this browser")
					errorMsg += "<br><br>" + this.$t("Beauty is around you, turn on your device's GPS")
					errorMsg +=
						"<br><br><a href='https://stendhapp.com/geolocalizzazione/' target='_blank'>" +
						this.$t("If you have any doubts consult our user guide") +
						"</a>"

					this.$ons.notification.alert(errorMsg, {
						title: this.$t("Caution")
					})
				}
			}
		},
		geoLocationError(error) {
			let errorMsg = ""
			switch (error.code) {
				case error.PERMISSION_DENIED:
					errorMsg = this.$t("User denied the request for Geolocation.")
					break
				case error.POSITION_UNAVAILABLE:
					errorMsg = this.$t("Location information is unavailable.")
					break
				case error.TIMEOUT:
					errorMsg = this.$t("The request to get user location timed out.")
					break
				case error.UNKNOWN_ERROR:
					errorMsg = this.$t("An unknown error occurred.")
					break
			}

			//errorMsg += "<br><br>" + this.$t("Enable geolocation to make the best use of Stendhapp")
			errorMsg += "<br><br>" + this.$t("Beauty is around you, turn on your device's GPS")
			errorMsg +=
				"<br><br><a href='https://stendhapp.com/geolocalizzazione/' target='_blank'>" +
				this.$t("If you have any doubts consult our user guide") +
				"</a>"

			this.$ons.notification.alert(errorMsg, {
				title: this.$t("Caution")
			})
		},
		checkDevice() {
			api.common.deviceDetector().then(data => {
				console.log("deviceDetector", data)

				if (data && data.status && data.status === 200 && data.body) {
					if (_.get(data.body, "device.type") === "desktop") {
						this.$ons.notification.toast(this.$t("For an optimized view we recommend using Stendhapp from the phone"), {
							timeout: 5000,
							animation: "fall"
						})
					}
				}
			})
		}
	},
	mounted() {
		setTimeout(() => {
			this.splashScreen = false

			console.log("APP STARTED")

			const that = this

			/*window.onbeforeunload = function(e) {
				console.log("APP EXIT")
				return that.$t("You are leaving Stendhapp. Click again if you really want to exit");
			};*/

			this.checkGeoLocation()
			if (this.isLogged) {
				this.checkDevice()

				setTimeout(() => {
					api.auth.saveInteraction("startup", {
						language: this.language,
						filters: this.filtersParsed,
						points: [],
						latitude: this.latitude,
						longitude: this.longitude
					})
				}, 2000)
			}

			EventBus.$on("login", () => {
				console.log("login")
				this.checkDevice()
			})

			/*function getLocation() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(showPosition,showError);
			} else {
				x.innerHTML = "Geolocation is not supported by this browser.";
			}
			}

			function showPosition(position) {
			x.innerHTML = "Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude;
			}

			function showError(error) {
			switch(error.code) {
				case error.PERMISSION_DENIED:
				x.innerHTML = "User denied the request for Geolocation."
				break;
				case error.POSITION_UNAVAILABLE:
				x.innerHTML = "Location information is unavailable."
				break;
				case error.TIMEOUT:
				x.innerHTML = "The request to get user location timed out."
				break;
				case error.UNKNOWN_ERROR:
				x.innerHTML = "An unknown error occurred."
				break;
			}
			}*/
		}, 3000)

		function keep_user_on_tab(count) {
			for (let i = 0; i < count; i++) {
				setTimeout(() => {
					console.log("KEEP USER ON TAB:", i + 1)
					history.pushState({}, "Stendhapp®", "#")
				}, i * 3000)
			}
		}
		function close_tab() {
			window.CLOSING = true
			for (let i = 0; i < 1000; i++) {
				history.back()
			}
		}

		const urlParams = new URLSearchParams(window.location.search)
		const uParam = urlParams.get("u")
		const lParam = urlParams.get("l")
		const pParam = urlParams.get("p")
		if (uParam || pParam) {
			if (uParam && !pParam) {
				// verifica utente
				this.forceLanguage = lParam
				this.userVerification = true
				this.verificationText = lParam === "en" ? "We are checking your email..." : "Stiamo verificando la tua email..."

				api.auth
					.verify(uParam)
					.then(data => {
						this.verificationText =
							lParam === "en"
								? "Thanks, you can now use Stendhapp on your smartphone"
								: "Grazie, ora puoi utilizzare Stendhapp sul tuo smartphone"
						/*this.$ons.notification.alert(this.$t("Verified user"), {
							title: this.$t("Done")
						})
						setTimeout(() => {
							window.location.href = "/"
						}, 2000)*/
					})
					.catch(err => {
						this.verificationText = lParam === "en" ? "Check email error" : "Errore verifica email"
						//this.$ons.notification.toast(this.$t("Save error"), { timeout: 1000, animation: "fall" })
					})
			}
			if (uParam && pParam) {
				// reset password

				this.form.email = uParam
				this.form.password = pParam
				this.loginType = "login"
				this.login()
			}
		} else {
			/* KEEP USER ON TAB */
			setTimeout(() => {
				keep_user_on_tab(2)
			}, 3000)

			window.addEventListener("popstate", () => {
				console.log("location changed!")
				if (!window.CLOSING) {
					if (confirm(this.$t("You are leaving Stendhapp. Click again if you really want to exit"))) {
						close_tab()
					} else {
						keep_user_on_tab(1)
					}
				}
			})
		}
	},
	components: {
		MainPage
	}
}
</script>

<style lang="scss">
body {
	background: #fff;
}
html,
body {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
}
#app {
	max-width: 640px;
	margin: 0 auto;
	box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.63);

	//font-family: 'Avenir', sans-serif;
	font-family: "Barlow", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	//height: 100vh;
	-webkit-overflow-scrolling: touch;
}

#splash-screen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-image: url(../public/images/splashscreen.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 1000;
}

#login-box {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-image: url(../public/images/bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 999;
	color: #fff;
	text-align: center;
	padding: 10px;
	box-sizing: border-box;
	overflow: auto;

	h1 {
		font-size: 24px;
	}

	h4,
	p,
	a {
		opacity: 0.7;
		font-size: 12px;
	}

	.logo-box {
		img {
			width: 80px;
		}
	}

	.login-form {
		border: 1px solid rgba(255, 255, 255, 0.4);
		border-radius: 8px;
		width: 80%;
		max-width: 280px;
		margin: 0 auto;
		background: rgba(255, 255, 255, 0.1);
		margin-top: 40px;
		overflow: hidden;

		.button-box {
			background: rgba(255, 255, 255, 0.5);

			button {
				margin: 0;
				border: 0;
				border-radius: 8px;
				background: transparent;
				outline: none;
				cursor: pointer;
				font-weight: 500;
				font-size: 14px;

				&.active {
					background: rgba(255, 255, 255, 0.8);
					padding: 10px 0;
					color: #1d84ff;
				}
			}
		}

		input:not(.checkbox__input),
		.select {
			width: 100%;
			display: block;
			border: none;
			border-top: 1px solid rgba(255, 255, 255, 0.336);
			padding: 15px;
			background: transparent;
			margin: 0;
			color: #fff;
			font-weight: 500;
			box-sizing: border-box;
			font-size: 14px;
		}

		.select {
			.select-input {
				font-size: 14px;
				height: 18px;
				line-height: 18px;
				color: #fff;
				font-weight: 500;
				font-family: "Barlow", -apple-system, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", sans-serif;
			}

			&.not-selected {
				.select-input {
					opacity: 0.3;
				}
			}
		}

		.disclaimer {
			text-align: left;

			.label {
				font-size: 14px;
				padding: 8px 16px;
			}

			.text {
				font-size: 12px;
				padding: 8px 16px;
			}

			.link {
				text-decoration: underline;
				font-size: 12px;
				padding: 8px 16px;
				color: #fff;
				display: block;
			}

			.list {
				background: transparent;

				.list-item {
					color: #fff;
					font-size: 12px;
				}
			}
		}

		.email-error {
			color: #a94ef6;
			font-size: 14px;
			margin-bottom: 10px;
			padding: 0 10px;
		}
	}

	.image-square-box {
		margin-top: 40px;
		margin-left: -10px;
		margin-right: -10px;
		border-bottom: 1px solid #fff;

		img {
			width: 50px;
			position: relative;
			top: 5px;
		}
	}

	.sub-label {
		margin-top: 2px;
	}

	button.submit {
		background: #1d84ff;
		padding: 10px 0;
		width: 80%;
		max-width: 280px;
		margin: 0 auto;
		display: block;
		border: none;
		border-radius: 8px;
		color: #fff;
		margin-top: 20px;
		cursor: pointer;
		font-weight: 500;

		&[disabled] {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	a.forgot-password-btn {
		cursor: pointer;
		color: #a99bbd;
		display: block;
		margin-top: 20px;
		font-weight: 500;
	}
}

#user-verification {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-image: url(../public/images/bg-mid.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 1000;
	color: #fff;
	text-align: center;
	overflow: auto;

	h1 {
		position: absolute;
		top: 50%;
		width: 100%;
		transform: translateY(-50%);
		padding: 30px;
		box-sizing: border-box;
		margin: 0;
	}
}

.main-page {
	&.hide {
		opacity: 0;
	}
}

ons-action-sheet {
	z-index: 10005 !important;
}

.toolbar--material__left:empty,
.toolbar--material__right:empty {
	min-width: 72px;
}

.alert-dialog {
	max-height: 95%;
	overflow: auto;
}

.select-input {
	option {
		background: black;
		color: white;
	}
}
</style>
