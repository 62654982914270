<template>
	<div class="discover-page flex column">
		<v-ons-toolbar modifier="noshadow transparent cover-content">
			<div class="left">
				<!--<v-ons-toolbar-button icon="md-settings" @click="openSettings"></v-ons-toolbar-button>-->
			</div>
			<div class="center">Stendhapp®</div>
			<div class="right">
				<!--<v-ons-toolbar-button icon="md-search"></v-ons-toolbar-button>-->
			</div>
		</v-ons-toolbar>

		<div class="box grow flex column">
			<div class="header-img"></div>
			<img src="../assets/discover-icon-2.png" id="discover-icon" alt="discover-icon" />
			<div class="content box grow flex column">
				<div class="label">
					{{ $t("Touch the map to find the Beauty around you") }}
				</div>
				<div class="map-box box grow">
					<map-small-component-static @openMap="openMap"></map-small-component-static>
				</div>
				<div class="label flex align-center justify-space-between arrows">
					<i class="mdi mdi-chevron-left"></i>
					<span>{{ $t("Recommendations for you") }}</span>
					<i class="mdi mdi-chevron-right"></i>
				</div>
				<div class="nearest-box">
					<div
						class="nearest-item"
						v-for="item in nearest"
						:key="item._id"
						:class="{ sponsor: item.sponsor }"
						:style="'background-image:url(' + getImage(item) + ')'"
						@click="openItem(item)"
					>
						<div class="title">{{ getName(item) }}</div>
						<div class="img-box">
							<img :src="'map-icons/' + item.category + '.png'" alt="category-icon" />
						</div>
					</div>
				</div>
				<!--
				<div class="label">
					{{$t("Categories")}}
				</div>
				<div class="nearest-box">
					<div class="nearest-item" v-for="item in nearest" :key="item.type" :style="'background-image:url(images/categories/'+item.type+'.jpg)'" @click="setCatAndGo(item.type)">
						<div class="title">{{$t(ld.capitalize(item.type))}}</div>
						<div class="img-box">
							<img :src="'map-icons/'+item.type+'.png'" alt="category-icon">
						</div>
					</div>
				</div>
				-->
			</div>
		</div>
	</div>
</template>

<script>
import MapSmallComponentStatic from "./MapSmallComponentStatic"
import _ from "lodash"
import api from "../api"
import { Const } from "../const"
import { EventBus } from "@/event-bus.js"

export default {
	name: "discover-page",
	data() {
		return {
			ld: _,
			nearest: [],
			/*nearest: [
				{type: "museums"},
				{type: "church"},
				{type: "monuments"},
				{type: "buildings"},
				{type: "archeological"},
				{type: "art"},
				{type: "music"},
				{type: "theatre"},
				{type: "unesco"},
				{type: "literature"},
				{type: "history"},
				{type: "nature"},
				{type: "food"},
				{type: "wine"},
				{type: "festivals"},
			]*/
			navigator: navigator,
			lat: 0,
			lon: 0,
			photodir: Const.photodir,
			tempdir: Const.tempdir,
			watchPositionIntervalTime: 30000,
			BASE_URL: api.getBaseUrl(),
			DOMAIN_URL: api.getDomainUrl()
		}
	},
	computed: {
		isLogged() {
			return this.$store.state.isLogged
		},
		language() {
			return this.$store.getters.language
		},
		filtersParsed() {
			return this.$store.getters.filtersParsed
		},
		canGeolocation() {
			return this.$store.getters.canGeolocation
		}
	},
	methods: {
		openMap() {
			this.$emit("openMap")
		},
		openSettings() {
			this.$emit("openSettings")
		},
		setCatAndGo(cat) {
			//this.$store.dispatch('setOneFilter', cat)
			EventBus.$emit(`open:map`, { category: cat })
		},
		getCurrentPosition() {
			return new Promise((resolve, reject) => {
				if (this.canGeolocation) {
					this.navigator.geolocation.getCurrentPosition(
						position => {
							resolve(position)
						},
						error => {
							reject(error)
						},
						{
							maximumAge: 3000,
							timeout: 10000,
							enableHighAccuracy: true
						}
					)
				} else {
					reject({ ack: false })
				}
			})
		},
		watchPosition() {
			this.getCurrentPosition()
				.then(({ coords }) => {
					let goToSetCurrentPos = true

					if (
						parseFloat(coords.latitude).toFixed(5) === parseFloat(this.lat).toFixed(5) &&
						parseFloat(coords.longitude).toFixed(5) === parseFloat(this.lon).toFixed(5)
					) {
						goToSetCurrentPos = false
					}

					if (goToSetCurrentPos && this.isLogged) {
						this.lat = coords.latitude
						this.lon = coords.longitude
						this.getPoints(coords)
					}

					setTimeout(() => {
						this.watchPosition()
					}, this.watchPositionIntervalTime)
				})
				.catch(err => {
					setTimeout(() => {
						this.watchPosition()
					}, this.watchPositionIntervalTime)
				})
		},
		getPoints(coords) {
			const filters = this.filtersParsed
				.filter(f => f.active)
				.map(f => f.name)
				.join(",")

			api.pdi
				.getAll({
					id: "discover",
					lat: coords.latitude,
					lon: coords.longitude,
					filters
				})
				.then(data => {
					if (data && data.status && data.status === 200 && data.body) {
						this.nearest = _.compact(data.body)
						this.$store.commit("pushSponsor", data.body)

						api.auth.saveInteraction("discover", {
							language: this.language,
							filters: this.filtersParsed,
							points: this.nearest,
							latitude: this.lat,
							longitude: this.lon
						})
					}
				})
		},
		openItem(item) {
			EventBus.$emit(`open:pdi`, item)
		},
		getImage(item) {
			if (item && item.photo) return encodeURI(this.DOMAIN_URL + this.photodir + "/" + item.photo)
			else return "images/placeholder.jpg"
		},
		getName(item) {
			switch (this.$i18n.locale) {
				case "it":
					return item.name || item.nameEN
				case "en":
					return item.nameEN || item.name
				default:
					return ""
			}
		},
		init() {
			this.getCurrentPosition()
				.then(({ coords }) => {
					this.getPoints(coords)
				})
				.catch(err => {})
		}
	},
	mounted() {
		this.watchPosition()

		EventBus.$on("login", () => {
			console.log("login")
			this.init()
		})

		EventBus.$on("update:settings", () => {
			console.log("update:settings")
			this.init()
		})
	},
	components: {
		MapSmallComponentStatic
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.discover-page {
	height: 100%;
	background: #fff;

	.toolbar {
		.toolbar__title {
			color: #fff;
		}
		.toolbar-button {
			color: #fff;
			opacity: 0.5;
		}

		&.tab-index-0 {
			box-shadow: 0px 65px 30px -40px rgba(0, 0, 0, 0.5) inset;
		}

		.toolbar--material__center {
			text-align: center;
		}
	}

	.header-img {
		background-image: url(../../public/images/discover-header.jpg);
		background-size: cover;
		background-position: center bottom;
		background-repeat: no-repeat;
		width: 100%;
		// height: 150px;
		height: 120px;
		color: #fff;
		text-align: center;
		padding-top: 60px;
		box-sizing: border-box;
	}

	#discover-icon {
		width: 100px;
		display: block;
		margin: 0 auto;
		margin-top: -40px;
	}

	.content {
		& > div {
			padding: 0 20px;
			padding-top: 20px;
		}

		.map-box {
			min-height: 120px;

			& > div {
				border-radius: 8px;
				box-shadow: 0px 3px 10px 0px rgba(100, 100, 100, 0.4);
				overflow: hidden;
			}
		}

		.label {
			&.arrows {
				font-size: 22px;
				color: #5741fd;

				span {
					font-size: 16px;
				}
			}
		}

		.nearest-box {
			overflow: hidden;
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;
			white-space: nowrap;
			padding-bottom: 20px;
			min-height: 95px;

			.nearest-item {
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				width: 180px;
				//height: 90px;
				height: 120px;
				display: inline-block;
				margin-right: 15px;
				border-radius: 8px;
				position: relative;
				box-shadow: 0px 0px 40px 0px #000 inset, 0px 3px 10px 0px rgba(100, 100, 100, 0.4);
				cursor: pointer;
				box-sizing: border-box;

				.title {
					color: #fff;
					font-size: 14px;
					position: absolute;
					top: 10px;
					left: 10px;
					right: 10px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					text-transform: capitalize;
					text-shadow: 0px 0px 2px #000;
					font-weight: 600;
				}

				.img-box {
					width: 40px;
					height: 40px;
					box-sizing: border-box;
					background: #fff;
					padding: 5px;
					border-radius: 4px;
					line-height: 40px;
					opacity: 0.9;
					text-align: center;
					display: block;
					position: absolute;
					bottom: 10px;
					left: 10px;

					img {
						width: 60%;
					}
				}

				&.sponsor {
					//border: 3px solid #FFD507;
					//box-shadow: 0px 0px 0px 3px #FFD507 inset, 0px 0px 20px 0px rgba(255, 213, 7, 0.9) inset, 0px 3px 10px 0px rgba(255, 213, 7, .5), 0px 7px 17px 0px rgba(0, 0, 0, 0.3);
					box-shadow: inset 0 0 0 5px rgba(255, 204, 51, 0.7), inset 0 0 20px 0 rgba(255, 204, 51, 0.9),
						0 3px 10px 0 rgba(255, 204, 51, 0.5), 0 7px 17px 0 rgba(0, 0, 0, 0.1);
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
</style>
